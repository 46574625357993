import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p"><em parentName="strong">{`Happy Thanksgiving!`}</em></strong></p>
    <p><strong parentName="p">{`*`}{`Our annual Free Thanksgiving Day workout will be today at 9:00am!
Partner WOD so bring a friend!  The gym will only be open for this
class.`}</strong></p>
    <p><em parentName="p">{`Partner Workout, divide reps as needed: `}</em></p>
    <p>{`1000M Ski Erg`}</p>
    <p>{`100 Situps`}</p>
    <p>{`75 Wall Balls (20/14)`}</p>
    <p>{`50 KBS’s (53/35)`}</p>
    <p>{`25 Ball Slams (30/20)`}</p>
    <p>{`50 Ring Rows`}</p>
    <p>{`75 Pushups`}</p>
    <p>{`100 Squats`}</p>
    <p>{`1000M Row`}</p>
    <p>{`for time.`}</p>
    <p><strong parentName="p">{`*`}{`There will be no open gym on Friday but all open gym members are
welcome to come to our classes at no charge.  The 6am & 6pm classes on
Friday are cancelled but the 10:30, 12:00, 4:00 & 5:00 classes are
available.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      